/** @format */
html.light {
  background-image: url("./assets/images/bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
/* scroll bar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(11, 129, 197);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 79, 158);
}
