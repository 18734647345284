/** @format */

.model {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 9999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model.open #close {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 5px;
  /* background-color: black; */
  color: white;
}

.model.open #close svg {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.model.open #fill-left {
  position: fixed;
  top: 50%;
  left: 10px;
}
.model.open #fill-left svg {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  color: white;
}

.model.open #fill-right {
  position: fixed;
  top: 50%;
  right: 10px;
}
.model.open #fill-right svg {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  color: white;
}
